




















































































































































































































































































































































































































































































.rollCallPage {
  .studentList {
    width: 20%;
    margin-right: 20px;
    .top {
      line-height: 40px;
      background-color: #5c6be8;
      color: #fff;
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
    .list {
      width: 100%;
      background-color: #f2f7fd;
      .list_item {
        padding: 10px 0;
        span {
          width: 5rem;
          text-align: center;
        }
      }
    }
  }
  .el-slider__bar,
  .el-slider__runway.disabled .el-slider__bar {
    background-color: #5c6be8 !important;
  }
  .el-slider__bar,
  .el-slider__runway {
    height: 8px;
  }
  .el-slider__marks {
    .el-slider__marks-text {
      font-size: 12px;
    }
    .el-slider__marks-text:first-child {
      transform: translateX(-100%);
    }
    .el-slider__marks-text:last-child {
      transform: translateX(0);
      width: 60px;
    }
  }
  .el-slider__button {
    height: 6px;
    width: 6px;
    border: 0;
  }
  .el-slider__button-wrapper {
    top: -14px;
  }
}
